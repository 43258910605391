import type { SourceInformationProps } from './types';
import React from 'react';
import { isSourceStale } from 'Utils/contactSyncSource';
import { Styled } from './index.styles';

export const SourceInformation: React.FC<SourceInformationProps> = ({
  sourceType,
  sourceEmail,
  sourceState,
  mapProperLogoProvide,
  navigate,
}) => {
  return (
    <Styled.Container>
      <Styled.Label>{'Source'}</Styled.Label>

      <Styled.InfoWrapper isSourceStale={isSourceStale(sourceState)}>
        <Styled.Logo src={mapProperLogoProvide(sourceType, true)} />
        <Styled.Email>{sourceEmail}</Styled.Email>
      </Styled.InfoWrapper>

      {isSourceStale(sourceState) && (
        <Styled.WarningContainer>
          <Styled.WarningIcon icon="warning-filled" />
          <Styled.Warning>
            <Styled.WarningTitle>{'Warning: '}</Styled.WarningTitle>
            <Styled.WarningText>
              {sourceType === 'icloud'
                ? 'iCloud isn’t a supported source. Any changes will be saved, but won’t be synced with iCloud.'
                : ' this account isn’t synced anymore, please login again.'}
            </Styled.WarningText>
          </Styled.Warning>

          <Styled.ManageSourcesButton
            onClick={() => {
              navigate('/addressBook/sources');
            }}
            testid="contacts-sourceInformation"
            variant="primary"
          >
            {'Manage sources'}
          </Styled.ManageSourcesButton>
        </Styled.WarningContainer>
      )}
    </Styled.Container>
  );
};
