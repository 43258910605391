import type { Auth0ContextInterface } from '@auth0/auth0-react';
import createAuth0Client, {
  User as Auth0User,
  GetTokenSilentlyOptions,
  IdToken,
} from '@auth0/auth0-spa-js';
import { QueryClient } from '@tanstack/react-query';
import bhiveIconShort from 'Assets/images/bhive-logo-short.svg';
import bhiveIcon from 'Assets/images/bhive-logo.svg';
import autoLogOut from 'Assets/images/communicator-notification-right-50x50-01.ico';
import googleIconShort from 'Assets/images/google-short.svg';
import googleIcon from 'Assets/images/google.svg';
import iCloudIconShort from 'Assets/images/icloud-short.png';
import iCloudIcon from 'Assets/images/icloud.svg';
import linkedinIcon from 'Assets/images/linkedin.svg';
import microsoftIconShort from 'Assets/images/microsoft-icon-short.svg';
import microsoftIcon from 'Assets/images/microsoft-icon.svg';
import outLookIconShort from 'Assets/images/outlook-short.svg';
import outLookIcon from 'Assets/images/outlook.svg';
import salesforceIcon from 'Assets/images/salesforce.svg';
import type { AxiosResponse, CancelTokenSource } from 'axios';
import type { Identity } from 'Components/Settings/SocialAccounts/types';
import {
  API_ENDPOINTS,
  AUTH0,
  AUTH0_CONNECTION,
  BASE_CONTACT_API,
  BV_ENV,
  ContactsSearchApi,
  IS_ELECTRON,
  MSG_API_BASE_URI,
  NODE_ENV,
  NODE_ENV_TEST,
  PORTAL_URL,
  REDIRECT_URL,
  WAKEUP_INTERVAL,
  WAKEUP_TIMEOUT,
} from 'Constants/env';
import {
  CONFIG_RESPONSE,
  SIGN_IN_RESPONSE,
  STORE_CHAT_HTML,
  STORE_CHAT_RAW,
} from 'Constants/localstorage';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { ISourceAccount } from 'Interfaces/SourceAccount';
import { LDClient } from 'launchdarkly-js-client-sdk';
import * as localforage from 'localforage';
import { omitBy , get, has, isEmpty, uniq } from 'lodash';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
  when,
} from 'mobx';
import type { IObservableArray, IReactionDisposer, ObservableMap } from 'mobx';
import type { IPromiseBasedObservable } from 'mobx-utils';
import { createTransformer, fromPromise, now } from 'mobx-utils';
import { IEventParticipants, Scope } from 'Models/Calendar';
import { Contact, IContact } from 'Models/Contacts';
import {
  IPeople,
  IPersonModel,
  IPersonPicture,
  IPersonSignInTokenResponse,
  IPersonVideoFeature,
  PersonModel,
} from 'Models/PersonModel';
import {
  QUERY_KEY_CHECK_AVAILABILITY_PHONENUMBER,
  fetchAvailabilityPhoneNumberQuery,
} from 'Modules/person/index.requests';
import { usePersonStore } from 'Modules/person/index.store';
import type { InboundNumber } from 'Modules/person/index.types';
import moment from 'moment-timezone';
import * as queryString from 'query-string';
import type { DropdownItemProps } from 'semantic-ui-react';
import { BaseStore } from 'Stores/BaseStore';
import { RootStore } from 'Stores/RootStore';
import { isNullOrUndefined } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import {
  handleCodeReceived,
  sendIpcIdentify,
  sendIpcLoginUrl,
  sendIpcSaveCredentials,
  sendIpcUnreadCounts,
} from 'Utils/ipcRendererEvents';
import { loginTokenIsInHash } from 'Utils/login';
import { bugsnagClient } from 'Utils/logUtils';
import {
  formatNumberNoPlusIfUS,
  formatNumberWithNationalCode,
  isPhoneNumberMatch,
} from 'Utils/phoneUtil';
import { resizeImage } from 'Utils/resizeImage';
import API, {
  AUTH0_API,
  PURE_API,
  getAuthToken,
  getBearerAuthToken,
  removeAxiosAuthHeaders,
  setAPIHeader,
} from '../api';
import { MessagesGetRequest } from '../interfaces/apiDtos';
import * as intercom from '../utils/intercomWrapper';
import { hasTokenExpired, parseJwt } from '../utils/jwtUtils';
import { IWakeUpTickReactionData } from './UiStore';


const providerConfig = {
  ...AUTH0,
  client_id: AUTH0.clientId,
  redirect_uri: window.location.origin,
};
if (AUTH0_CONNECTION) {
  providerConfig.connection = AUTH0_CONNECTION;
}

export class PersonStore extends BaseStore {
  #queryClient: QueryClient;

  constructor(rootStore: RootStore, queryClient: QueryClient) {
    super(rootStore);
    this.#queryClient = queryClient;
    makeObservable(this);
  }

  private wakeUpCheckTick: IReactionDisposer = null;
  @observable
  lastWakeUpCheck: number = now(WAKEUP_INTERVAL); // 20000

  @observable public fileUploadedS3 = new Map<string, IPersonPicture>();

  @action
  setLastWakeUpCheck = (lastWakeUpCheck: number) =>
    (this.lastWakeUpCheck = lastWakeUpCheck);

  startWakeUpCheckTick = () => {
    this.wakeUpCheckTick = reaction(
      () =>
        ({
          // All calls to `now(WAKEUP_INTERVAL)` are synchronized to `WAKEUP_INTERVAL`
          wakeUpHeartBeat: now(WAKEUP_INTERVAL), // 10000 + (login within) WAKEUP_INTERVAL + (fireImmediately = false, delay until next interval tick) WAKEUP_INTERVAL
          isLoggedIn: this.rootStore.personStore.IsLoggedIn,
        } as IWakeUpTickReactionData),
      (tickData) => {
        if (!tickData.isLoggedIn) {
          return;
        }
        if (
          tickData.wakeUpHeartBeat >
            this.lastWakeUpCheck /* 20000 on first cycle */ +
              WAKEUP_TIMEOUT * 2 &&
          !this.rootStore.personStore.firstLogin
        ) {
          console.log('onWake: Heartbeat  missed');
          window.addEventListener('focus', this.checkOnlineStatus);
        }
        this.setLastWakeUpCheck(now(WAKEUP_INTERVAL));
      },
      {
        name: 'wakeUpCheck',
        fireImmediately: false,
        delay: 1000,
      }
    );
  };

  checkOnlineStatus = () => {
    console.log('onWake: checkOnlineStatus');
    window.removeEventListener('focus', this.checkOnlineStatus);
    if (navigator.onLine) {
      console.log('onWake: Already online');
    } else {
      window.addEventListener('online', this.reloadApp);
    }
  };

  reloadApp = () => {
    console.log('onWake: Reloading app');
    window.removeEventListener('online', this.reloadApp);
    bugsnagClient.notify(
      'App was explicitly reloaded due to a missed heartbeat',
      (event) => {
        event.severity = 'info';
      }
    );
    window.location.reload();
  };

  @observable
  addingNewContact = false;

  @action
  setAddingNewContact = (state: boolean) => (this.addingNewContact = state);

  @observable
  totalContacts = 0;

  @action
  setTotalContacts = (number: number) => (this.totalContacts = number);

  @observable
  isAddingContact = false;

  @action
  setIsAddingContact = (state: boolean) => (this.isAddingContact = state);

  @observable
  hasContacts = false;

  @action
  setHasContact = (state: boolean) => (this.hasContacts = state);

  @observable
  editContactDetails: Contact = null;

  @action
  setEditContact = (contact: Contact) => (this.editContactDetails = contact);

  @observable
  contactFilterValue: DropdownItemProps = null;

  @action
  setContactFilterValue = (value: DropdownItemProps) =>
    (this.contactFilterValue = value);

  /**
   List of contacts by phone number
   */
  @observable
  allContactByPhone: ObservableMap<string, Contact> = observable.map();

  @observable
  allContactByPhonePbo: ObservableMap<
    string,
    IPromiseBasedObservable<AxiosResponseT<any>>
  > = observable.map();

  @observable
  loadingContacts = false;

  @action
  setLoadingContact = (state: boolean) => (this.loadingContacts = state);

  @observable
  loadingInfinite = false;

  @action
  setLoaderInfinite = (state: boolean) => (this.loadingInfinite = state);

  @observable
  contactSearchTerm = '';

  @action
  setContactSearchTerm = (term: string) => (this.contactSearchTerm = term);

  @observable
  public loginTokenResponse: IPromiseBasedObservable<IPersonSignInTokenResponse>;

  @observable
  public allContactsTopBar: IObservableArray<Contact> = observable.array();

  @observable
  public allContacts: IObservableArray<Contact> = observable.array();

  @observable
  public personsById: ObservableMap<
    string,
    IPromiseBasedObservable<AxiosResponseT<PersonModel>>
  > = observable.map();

  @observable public loadPeopleStatus: IPromiseBasedObservable<
    AxiosResponseT<IPeople>
  > = null;

  @observable public loginStatus: IPromiseBasedObservable<
    AxiosResponseT<IPersonSignInTokenResponse>
  > = null;

  @observable public allSources: IObservableArray<ISourceAccount> =
    observable.array();

  @computed get PresenceSyncSeconds() {
    if (
      !isNullOrUndefined(this.rootStore.configStore.signedInPersonConfig) &&
      this.rootStore.configStore.signedInPersonConfig.state === 'fulfilled'
    ) {
      return (
        this.rootStore.configStore.signedInPersonConfig.value.data
          .presenceSyncSeconds || 60
      );
    } else {
      return 60;
    }
  }

  @observable
  loginPageEmail = '';

  @action
  setLoginPageEmail(email: string) {
    this.loginPageEmail = email;
  }

  @observable
  loginPagePassword = '';

  @action
  setLoginPagePassword(password: string) {
    this.loginPagePassword = password;
  }

  /**
   List for info sidebar only if clicked on contact,
   this list holds correspondence contacts by phone number
   */
  @observable
  listOfAllContactsWithNumber: ObservableMap<
    string,
    IPromiseBasedObservable<AxiosResponse<any>>
  > = observable.map();

  @observable
  contactPageNumber = 1;

  @action
  setContactPageNumber = (pageNumber: number) =>
    (this.contactPageNumber = pageNumber);

  @observable
  ipcCredentialsEmail = '';

  @action
  setIpcCredentialsEmail(email: string) {
    this.ipcCredentialsEmail = email;
  }

  @observable
  ipcCredentialsPassword = '';

  @action
  setIpcCredentialsPassword(password: string) {
    this.ipcCredentialsPassword = password;
  }

  /** True if the login process is currently running (waiting on Promise resolutions) */
  @observable
  public isLoggingIn = false;

  @action
  setIsLoggingIn = (isLoggingIn: boolean) => (this.isLoggingIn = isLoggingIn);

  @observable
  isEditingContact = false;

  @action
  setIsEditingContact = (state: boolean) => (this.isEditingContact = state);

  @observable
  showEditingLeavePopup: { show: boolean; wantedUrl: string } = {
    show: false,
    wantedUrl: '',
  };

  @action
  setEditingLeavePopup = (state: { show: boolean; wantedUrl: string }) =>
    (this.showEditingLeavePopup = state);

  /** `accountId` of the logged-in `Person` */
  @observable
  public loggedInAccountId = -1;

  /** `id` of the `Person` currently logged in. Value is -1 if not logged in. */
  @observable
  public loggedInPersonId = -1;

  /** `locationId` of the logged-in `Person` */
  @observable
  public loggedInLocationId = 0;

  @observable
  public loggedInEmail = '';

  @observable
  public loggedInPersonRole = '';

  @observable
  public personAvaliableFeatures: {
    video: IPersonVideoFeature;
    socialAccounts: boolean;
  } = {
    video: { enabled: false, expirationDate: '' },
    socialAccounts: false,
  };

  @observable
  newContactErrors: { show: boolean; message: string; field: string } = {
    show: false,
    message: '',
    field: '',
  };

  @action
  setErrorNewContact = (value: {
    show: boolean;
    message: string;
    field: string;
  }) => {
    this.newContactErrors = value;
    const timeout = setTimeout(() => {
      runInAction(
        () => (this.newContactErrors = { show: false, message: '', field: '' })
      );
      clearTimeout(timeout);
    }, 3000);
  };

  @observable
  addedNewSource: {
    show: boolean;
    sourceProvider: string;
    alreadyAddedMessage?: string;
  } = { show: false, sourceProvider: '' };

  @action
  setAddedNewSource = (value: {
    show: boolean;
    sourceProvider: string;
    alreadyAddedMessage?: string;
  }) => (this.addedNewSource = value);

  @observable
  firstLogin: boolean;

  @observable
  isAutoLogOut = false;

  @action
  setIsAutoLogout = (isAutoLogOut: boolean) => {
    this.isAutoLogOut = isAutoLogOut;
  };

  @action
  setFirstLogin = (firstLogin: boolean) => (this.firstLogin = firstLogin);

  @computed get IsLoggedIn() {
    return this.loggedInPersonId > 0;
  }

  @observable public isLoggingOut = false;
  @action
  setIsLoggingOut = (isLoggingOut: boolean) =>
    (this.isLoggingOut = isLoggingOut);

  @observable
  public showPersonDetails: { id: number | string; type: string } = {
    id: null,
    type: null,
  };

  @action
  public setShowPersonDetails = (id: number | string, type: string) => {
    return (this.showPersonDetails = { id, type });
  };

  @observable
  public auth0: Auth0ContextInterface<Auth0User> = null;

  @action
  setAuth0 = (auth0: Auth0ContextInterface<Auth0User>) => (this.auth0 = auth0);

  @observable
  public ldClient: LDClient | null = null;

  @action
  setLDClient(ldClient: LDClient) {
    this.ldClient = ldClient;
  }

  /**
   * Set the logged in Person's auth info.
   */
  @action
  setLoggedInInfo = (signInResp: IPersonSignInTokenResponse) => {
    this.loggedInAccountId = signInResp.account.id;
    this.loggedInPersonId =
      typeof signInResp.id === 'string'
        ? parseInt(signInResp.id, 10)
        : signInResp.id;
    this.loggedInEmail = signInResp.email;
    this.loggedInLocationId = signInResp.location.id;
    setAPIHeader('Authorization', `Bearer ${signInResp.token}`);

    // id, email and name must be set with setUser(), remaining props with addMetadata('user', {})
    bugsnagClient.setUser(
      `${this.loggedInAccountId}:${this.loggedInPersonId}`,
      signInResp.email,
      `${signInResp.firstName} ${signInResp.lastName}`
    );
    bugsnagClient.addMetadata('user', {
      personId: this.loggedInPersonId,
      accountId: this.loggedInAccountId,
      package: signInResp.package,
      locationId: signInResp.locationId,
    });

    bugsnagClient.addMetadata('custom', {
      nodeEnv: NODE_ENV,
      bvEnv: BV_ENV,
      apiUrl: MSG_API_BASE_URI,
    });

    // @ts-ignore
    if (window.document?.wasDiscarded) {
      bugsnagClient.notify('Discarded Tab', (event) => {
        event.severity = 'info';
      });
    }
  };

  @action
  tryLoginFromLocalForage = (setIsLoggingIn = true) => {
    if (NODE_ENV_TEST) {
      fromPromise.resolve();
    }
    return fromPromise(
      localforage
        .getItem<IPersonSignInTokenResponse>(SIGN_IN_RESPONSE)
        .then((localForageUser) => {
          if (!localForageUser) {
            console.debug(
              'localforage did not find the key ' + SIGN_IN_RESPONSE
            );
          }
          const hashToken = retrieveAndClearHashToken();
          const tkn =
            hashToken || (localForageUser && localForageUser.token) || null;
          if (!isEmpty(tkn)) {
            this.loginWithExistingToken(tkn, setIsLoggingIn);
          } else {
            this.setIsLoggingIn(false);
          }
          return tkn;
        })
    );
  };

  /** Select a `IPromiseBasedObservable` representing a `Person` */
  selectPersonById = createTransformer((personId: number) => {
    const pIdStr = isFinite(personId) ? personId.toString() : '';
    return this.personsById.has(pIdStr) ? this.personsById.get(pIdStr) : null;
  });

  getExtrContactByPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return null;
    const numNoPlus = formatNumberNoPlusIfUS(phoneNumber);
    const formatedPhoneNum = formatNumberWithNationalCode(numNoPlus);
    try {
      const [contact, contactAlreadyLoaded] =
        this.checkIsAlreadyLoadedContact(formatedPhoneNum);
      if (contact && contactAlreadyLoaded) {
        return contact;
      }
      if (!this.allContactByPhonePbo.has(formatedPhoneNum)) {
        this.allContactByPhonePbo.set(formatedPhoneNum, null);
        this.getLoadNewContact(formatedPhoneNum);
      }
    } catch (err) {
      bugsnagClient.notify(
        `Failed to get Contact by phoneNumber: ${phoneNumber}`,
        (event) => {
          event.severity = 'error';
        }
      );
      return null;
    }
  };

  getExtrContactByPhoneNumbers = (phoneNumbers: string[]) => {
    if (
      !phoneNumbers ||
      !Array.isArray(phoneNumbers) ||
      phoneNumbers.length === 0
    )
      return;

    const formatedPhoneNumbers = [];

    phoneNumbers.forEach((phoneNumber) => {
      const numNoPlus = formatNumberNoPlusIfUS(phoneNumber);
      const formatedPhoneNum = formatNumberWithNationalCode(numNoPlus);
      try {
        const [contact, contactAlreadyLoaded] =
          this.checkIsAlreadyLoadedContact(formatedPhoneNum);
        if (contact && contactAlreadyLoaded) {
          return;
        }
        if (!this.allContactByPhonePbo.has(formatedPhoneNum)) {
          this.allContactByPhonePbo.set(formatedPhoneNum, null);
          formatedPhoneNumbers.push(formatedPhoneNum);
        }
      } catch (err) {
        bugsnagClient.notify(
          `Failed to get Contact by phoneNumber: ${phoneNumber}`,
          (event) => {
            event.severity = 'error';
          }
        );
        return;
      }
    });

    formatedPhoneNumbers.length > 0 &&
      this.getLoadNewContacts(formatedPhoneNumbers);
  };

  getFromDirectory = async (phoneNumber: string): Promise<PersonModel> => {
    try {
      const res = await fromPromise(
        API.get(API_ENDPOINTS.DirectorySearch(phoneNumber, 'firstName', 1, 1))
      );
      return res?.data?.people?.length ? res.data.people[0] : null;
    } catch (err) {
      console.log('[error-searching-directory]: ', err);
    }
  };

  getOrSetExtrContactByPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return null;
    try {
      const formatedPhoneNum = formatNumberWithNationalCode(phoneNumber);
      const currentExternalPbo =
        this.allContactByPhonePbo.get(formatedPhoneNum);
      if (currentExternalPbo) return currentExternalPbo;

      return this.getLoadNewContact(formatedPhoneNum);
    } catch (err) {
      bugsnagClient.notify(
        `Failed to get Contact by phoneNumber: ${phoneNumber}`,
        (event) => {
          event.severity = 'error';
        }
      );
      return null;
    }
  };

  getExtrContactFromPboList = (phoneNumber: string) => {
    const formatedPhoneNum = formatNumberWithNationalCode(phoneNumber);
    if (this.allContactByPhonePbo.has(formatedPhoneNum)) {
      return this.allContactByPhonePbo.get(formatedPhoneNum);
    }
    return null;
  };

  @action
  getLoadNewContact = async (
    phoneNumber: string,
    contactCancelToken?: CancelTokenSource
  ) => {
    try {
      const formatedPhone = formatNumberWithNationalCode(phoneNumber);
      const respPbo = fromPromise(
        API.get(`${BASE_CONTACT_API}contacts/match-phone-number-multiple`, {
          params: { phoneNumber },
          cancelToken: contactCancelToken?.token,
        })
      );
      this.allContactByPhonePbo.set(formatedPhone, respPbo);
      respPbo.then(
        (resp) => {
          if (resp.data.items[0]) {
            const contactInstance = new Contact(resp.data.items[0]);
            const isInList = this.getContactFromList(phoneNumber);
            !isInList
              ? this.handleAddNewContactSuccess(contactInstance)
              : runInAction(() =>
                  this.allContactByPhone.set(formatedPhone, contactInstance)
                );
          }
          return resp.data.items;
        },
        (error) => {
          bugsnagClient.notify(
            `Failed to load contact with ${phoneNumber}`,
            (event) => {
              event.severity = 'error';
            }
          );
        }
      );
      return respPbo;
    } catch (err) {
      bugsnagClient.notify(
        `Failed to load contact with ${phoneNumber}`,
        (event) => {
          event.severity = 'error';
        }
      );
      return null;
    }
  };

  @action
  getLoadNewContacts = async (
    phoneNumbers: string[],
    contactCancelToken?: CancelTokenSource
  ) => {
    try {
      const formatedPhoneNumbers = phoneNumbers.map(
        formatNumberWithNationalCode
      );

      const respPbo = fromPromise(
        API.get(`${BASE_CONTACT_API}contacts/match-phone-numbers`, {
          params: { phoneNumber: phoneNumbers },
          cancelToken: contactCancelToken?.token,
        })
      );

      formatedPhoneNumbers.forEach((formatedPhoneNumber) =>
        this.allContactByPhonePbo.set(formatedPhoneNumber, respPbo)
      );

      respPbo.then(
        (resp) => {
          if (resp.data) {
            for (let index = 0; index < resp.data.length; index++) {
              if (isNullOrUndefined(resp.data[index])) continue;

              const contactInstance = new Contact(resp.data[index]);
              const isInList = this.getContactFromList(phoneNumbers[index]);

              !isInList
                ? this.handleAddNewContactSuccess(contactInstance)
                : runInAction(() =>
                    this.allContactByPhone.set(
                      formatedPhoneNumbers[index],
                      contactInstance
                    )
                  );
            }
          }
          return resp.data;
        },
        (error) => {
          bugsnagClient.notify(
            `Failed to load contacts ${phoneNumbers}`,
            (event) => {
              event.severity = 'error';
            }
          );
        }
      );
      return respPbo;
    } catch (err) {
      bugsnagClient.notify(
        `Failed to load contacts ${phoneNumbers}`,
        (event) => {
          event.severity = 'error';
        }
      );
      return null;
    }
  };

  checkIsAlreadyLoadedContact = (phoneNumber: string): [Contact, Boolean] => {
    const formatedPhoneNum = formatNumberWithNationalCode(phoneNumber);
    const hasRespByPhoneNum = this.allContactByPhone.has(formatedPhoneNum);
    const contact =
      hasRespByPhoneNum && this.allContactByPhone.get(formatedPhoneNum);
    if (contact) {
      return [contact, true];
    } else {
      const isInList = this.getContactFromList(phoneNumber);
      return [isInList, !!isInList];
    }
  };

  getContactFromList = (phoneNumber: string) => {
    return this.allContacts.find((item) => {
      return item?.phoneNumbers.find(
        (phone) =>
          isPhoneNumberMatch(phoneNumber, phone.number) ||
          isPhoneNumberMatch(phoneNumber, phone.normalizedNumber)
      );
    });
  };

  getAllContactsWithSameNum = (phoneNumber) => {
    try {
      if (this.listOfAllContactsWithNumber.has(phoneNumber)) {
        return this.listOfAllContactsWithNumber.get(phoneNumber);
      }
      const resp = fromPromise(
        API.get(`${BASE_CONTACT_API}contacts/match-phone-number-multiple`, {
          params: { phoneNumber },
        })
      );
      this.listOfAllContactsWithNumber.set(phoneNumber, resp);
      return resp;
    } catch (err) {
      bugsnagClient.notify(
        `Failed to get all contacts by phone: ${phoneNumber}`,
        (event) => {
          event.severity = 'error';
        }
      );
    }
  };

  selectPersonByExt = createTransformer((extension: number) => {
    return fromPromise<AxiosResponseT<PersonModel>>(
      new Promise((resolve, reject) => {
        const extStr = isFinite(extension) ? extension.toString() : '';
        if (extStr.length === 0) {
          reject(`Empty or invalid extension provided to selectPersonByExt`);
        }
        this.personsById.forEach((value) => {
          return value.case({
            fulfilled: (res) => {
              if (
                res.data.extensionNumber === extStr ||
                res.data.extensionNumber === 'x' + extStr
              ) {
                resolve(res);
              }
            },
          });
        });
        // NO match
        reject(`selectPersonByExt found no match for ${extStr}`);
      })
    );
  });

  /** If the PBO for a `Person` is 'fulfilled', return the value, otherwise `null` */
  selectPersonValueById = createTransformer((personId: number) => {
    const personPbo = this.selectPersonById(personId);
    if (personPbo !== null && personPbo.state === 'fulfilled') {
      return personPbo.value;
    }
    return null;
  });

  selectPersonPropertiesById = createTransformer((personId: number) => {
    const pr = this.selectPersonValueById(personId);
    if (pr !== null) {
      return pr.data;
    }
    return null;
  });

  waitUntilLoggedIn = () => {
    return when(
      () => {
        return this.rootStore.personStore.IsLoggedIn;
      },
      { timeout: 10000 }
    );
  };

  /** Returns a `Promise` for removing `SIGN_IN_RESPONSE` from localforage */
  @action
  clearAllData = () => {
    if (this.wakeUpCheckTick !== null) {
      this.wakeUpCheckTick();
      this.wakeUpCheckTick = null;
    }
    this.personsById.clear();
    this.loadPeopleStatus = null;
    this.loginStatus = null;
    localforage.removeItem(STORE_CHAT_HTML);
    localforage.removeItem(STORE_CHAT_RAW);
    this.loggedInAccountId = -1;
    this.loggedInPersonId = -1;

    removeAxiosAuthHeaders();
    return localforage
      .removeItem(SIGN_IN_RESPONSE)
      .then(() => (this.loginTokenResponse = null));
  };

  @action
  handleVideoTrialInterested = () => {
    pushToGTMDataLayer('Video ProSeat, interested', {
      userId: this.loggedInPersonId,
    });
  };

  /** Load a single `Person` into `this.personsById` */
  @action
  loadPersonByIdGet = (
    personId: number,
    personCancelToken?: CancelTokenSource
  ) => {
    try {
      const loadPersonPbo = fromPromise<AxiosResponseT<PersonModel>>(
        API.get(API_ENDPOINTS.PeoplePersonById(personId), {
          cancelToken: personCancelToken?.token,
        })
      );
      loadPersonPbo.then(this.loadPersonByIdGetSuccess, (reason) => {
        this.loadPersonByIdGetFailure(reason, personId);
      });
      this.personsById.set(personId.toString(), loadPersonPbo);
      return this.personsById.get(personId.toString());
    } catch (err) {
      console.warn(
        'Failed to load Person ' +
          personId.toString() +
          ' this may be caused by a removed Person, which is not an error.',
        err
      );
    }
  };

  /** Load a single `Person` into `this.personsById` only if the key (`personId`) is missing */
  @action
  loadPersonByIdGetIfMissingGet = (
    personId: number,
    personCancelToken?: CancelTokenSource
  ) => {
    const pidStr = personId?.toString();
    if (pidStr && !this.personsById.has(pidStr)) {
      return this.loadPersonByIdGet(personId, personCancelToken);
    }
    return this.personsById.get(pidStr);
  };

  /** Convert plain object `IPersonModel` response DTO into `PersonModel` with observable properties and actions. */
  @action
  private loadPersonByIdGetSuccess = (
    personResp: AxiosResponseT<IPersonModel>
  ) => {
    this.personsById.set(
      personResp.data.id.toString(),
      fromPromise.resolve({
        ...personResp,
        data: PersonModel.FromResponseDto(personResp.data),
      })
    );
  };

  /** Creates deleted users and formats them accordingly */
  @action
  private loadPersonByIdGetFailure = (reason, personId: number) => {
    const personModel = new PersonModel(
      personId,
      null,
      null,
      'Removed User #' + personId,
      '',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (reason.response.status !== 404) {
      this.personsById.set(
        personId.toString(),
        fromPromise.resolve({ ...reason, data: personModel })
      );
    }
  };

  @action
  loadPeopleGet = () => {
    this.loadPeopleStatus = fromPromise(
      API.get(API_ENDPOINTS.DirectorySearch('', 'firstName', 10000, 1))
    );
    return this.loadPeopleStatus.then(this.loadPeopleGetSuccess, (reason) =>
      this.rootStore.notificationStore.addAxiosErrorNotification(
        reason,
        'Error loading People'
      )
    );
  };

  /**
   * Maps each person in `people` into `this.conversationByIdMap` as `AxiosResponseT<PersonModel>`.
   * Includes a shallow clone of the `AxiosResponseT` fields (except `data`) from `this.loadPeopleStatus`
   */
  @action
  private loadPeopleGetSuccess = (resp: AxiosResponseT<IPeople>) => {
    resp.data?.people?.forEach((person) => {
      if (person.id !== this.loggedInPersonId) {
        this.personsById.set(
          person.id.toString(),
          fromPromise.resolve({
            ...resp,
            data: PersonModel.FromResponseDto(person),
          } as AxiosResponseT<PersonModel>)
        );
      }
    });
    return Promise.resolve(this.personsById);
  };

  authenticateUser = async (): Promise<IdToken> => {
    const auth0 = await createAuth0Client(providerConfig);
    if (IS_ELECTRON && has(window, 'ipcRenderer')) {
      const auth0Url = await auth0.buildAuthorizeUrl({
        prompt: 'login',
        max_age: 0,
        scope: 'openid',
      });
      return new Promise((resolve, reject) => {
        handleCodeReceived(async (code, url) => {
          try {
            await auth0.handleRedirectCallback(decodeURIComponent(url));
            const res = await auth0.getIdTokenClaims();
            resolve(res);
          } catch (error) {
            reject(error);
          }
        });
        sendIpcLoginUrl(auth0Url);
      });
    } else {
      await auth0.loginWithPopup({
        max_age: 0,
      });
      return await auth0.getIdTokenClaims();
    }
  };

  prepareAxiosAuth0Headers = async (
    auth0: Auth0ContextInterface<Auth0User>
  ) => {
    const accessToken = await auth0.getAccessTokenSilently({
      scope:
        'openid email profile read:current_user update:current_user_identities',
    });
    if (!accessToken) {
      throw new Error(
        `Couldn't complete your request, seems that you have been logged out!`
      );
    }
    AUTH0_API.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  };

  linkSocailAccount = async (auth0: Auth0ContextInterface<Auth0User>) => {
    await this.prepareAxiosAuth0Headers(auth0);
    const {
      __raw: targetUserIdToken,
      email_verified,
      email,
    } = await this.authenticateUser();

    if (!email_verified) {
      throw new Error(
        `Account linking is only allowed to a verified account. Please verify your email ${email}.`
      );
    }

    return AUTH0_API.post(`${auth0.user.sub}/identities`, {
      link_with: targetUserIdToken,
    });
  };

  unlinkSocialAccount = async (
    auth0: Auth0ContextInterface<Auth0User>,
    secondaryIdentity: Identity
  ) => {
    const { provider, user_id } = secondaryIdentity;
    await this.prepareAxiosAuth0Headers(auth0);
    return AUTH0_API.delete(
      `${auth0.user.sub}/identities/${provider}/${user_id}`
    );
  };

  getAuth0UserProfile = async (
    auth0: Auth0ContextInterface<Auth0User>,
    userId: string
  ) => {
    await this.prepareAxiosAuth0Headers(auth0);
    return AUTH0_API.get(`${userId}`);
  };

  whetherTheCallExists = () => {
    const {
      rootStore: {
        phoneStore: { ActivePhoneCall, phoneCalls },
      },
    } = this;
    return (
      isNullOrUndefined(ActivePhoneCall) ||
      isNullOrUndefined(phoneCalls) ||
      !ActivePhoneCall.isCallConnected ||
      !ActivePhoneCall.isCallConnecting ||
      !ActivePhoneCall.isCallOnHold ||
      !ActivePhoneCall.isCallMuted
    );
  };

  /** Attempt to use the data in `this.localForageUserLoaded` to call `loginWithExistingToken` */
  @action
  tryRefreshLocalLogin = () => {
    const {
      rootStore,
      rootStore: {
        personStore,
        pusherStore,
        uiStore,
        conversationStore,
        phoneStore,
      },
    } = this;
    if (NODE_ENV_TEST) {
      return;
    }
    if (this.whetherTheCallExists()) {
      clearTimeout(phoneStore.setTimeOut);
      phoneStore.setIsTenSeconds(false);
    }
    this.loginTokenResponse = this.tryLoginFromLocalForage(false);
    this.loginTokenResponse.then((ltr) => {
      // No token retrieved from localforage or url hash
      if (ltr === null) {
        rootStore.clearAllData();
        // TODO: (throw error/warn?) indicate that login has expired
        this.rootStore.routerStore.replace('/');
      } else if (pusherStore.isOnline && navigator.onLine) {
        if (
          personStore.allContacts.length === 0 &&
          !personStore.loadingContacts
        ) {
          this.getAllContacts();
        }
        this.loginWithExistingToken(ltr.token, false);
        uiStore.loadAllPeoplesPresence();
        this.loadMessagesSinceNewestMessageForAllConversations();
        conversationStore.loadConversationsGet();
        personStore.getAllContacts();
        phoneStore.runRegister();
      } else {
        rootStore.clearAllData();
        this.rootStore.routerStore.replace('/');
      }
    });
  };

  getRefreshedToken = async () => {
    const token = getAuthToken();
    if (token && hasTokenExpired(token)) {
      return await this.exchangeForBVToken({ ignoreCache: true });
    }
  };

  exchangeForBVToken = async (
    options: GetTokenSilentlyOptions
  ): Promise<string | undefined> => {
    try {
      const accToken = await this.auth0.getAccessTokenSilently({
        grant_type: 'refresh token',
        ...options,
      });
      return await this.auth0ToBHiveToken(accToken);
    } catch (e) {
      await this.logout();
    }
  };

  @observable
  refreshingToken = false;

  @action
  setRefreshingToken = (refreshing: boolean) =>
    (this.refreshingToken = refreshing);

  /** Attempt to use the data in `this.localForageUserLoaded` to call `loginWithExistingToken` */

  @action
  loadMessagesSinceNewestMessageForAllConversations = () => {
    const {
      rootStore: { messageStore, preferenceStore },
    } = this;
    messageStore.groupedMessagesByConversationMap.forEach((element) => {
      if (element.NewestMessage !== null) {
        messageStore.loadMessagesSinceNewestMessage(
          element.conversationId,
          element.NewestMessage.id
        );
      } else {
        const msgsGet: MessagesGetRequest = {
          Limit: 31,
          ShowCallMessagesInChat:
            preferenceStore.preferences.showCallMessagesInChat,
        };
        messageStore.loadConversationMessages(element.conversationId, msgsGet);
      }
    });
  };

  @action
  loginWithExistingToken = (token: string, setIsLoggingIn = true) => {
    if (setIsLoggingIn) {
      this.setIsLoggingIn(true);
    }

    this.setFirstLogin(false);
    if (!isNullOrUndefined(localforage.getItem(STORE_CHAT_HTML))) {
      localforage
        .getItem<ObservableMap<string>>(STORE_CHAT_HTML)
        .then((data) => {
          this.rootStore.messageStore.messageDraftHtmlMap.merge(data);
        });
      localforage
        .getItem<ObservableMap<string>>(STORE_CHAT_RAW)
        .then((data) => {
          this.rootStore.messageStore.messageDraftRawMap.merge(data);
        });
    }
    if (!isNullOrUndefined(token)) {
      const link: HTMLLinkElement =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      this.loginStatus = fromPromise(
        PURE_API.get(API_ENDPOINTS.AuthLogin, {
          headers: { Authorization: `Bearer ${token}` },
        })
      );
      // Removed toast notification, use inline Message if login failed
      return this.loginStatus.then(
        (resp) => {
          const data: IPersonSignInTokenResponse = {
            ...resp.data,
            token,
          };
          /** if setIsLoggingIN is false means we already have a token and we wont reconfig */
          this.loginSuccess(
            { ...resp, data } as AxiosResponseT<IPersonSignInTokenResponse>,
            false,
            false,
            setIsLoggingIn
          );
        },
        (err) => {
          this.setIsLoggingIn(false);
          if (err && err.response && err.response.status === 401) {
            this.rootStore.clearAllData();
            link.href = autoLogOut;
            document.getElementsByTagName('head')[0].appendChild(link);
            // TODO: (throw error/warn?) indicate that login has expired
            this.rootStore.personStore.setIsAutoLogout(true);
            this.rootStore.routerStore.replace('/');
            console.error(err);
          } else {
            throw err;
          }
        }
      );
    } else {
      console.warn('the token is empty');
    }
  };

  @action
  getUserProfile = async (): Promise<PersonModel | undefined> => {
    try {
      const resp: AxiosResponseT<IPersonSignInTokenResponse> = await API.get(
        API_ENDPOINTS.AuthLogin
      );
      const signedInPerson: PersonModel = PersonModel.FromResponseDto(
        resp.data
      );
      runInAction(() => {
        this.personsById.set(
          signedInPerson.id.toString(),
          fromPromise.resolve({
            ...resp,
            data: signedInPerson,
          })
        );
      });
      return signedInPerson;
    } catch (err) {
      bugsnagClient.notify('Failed to get user profile', (event) => {
        event.severity = 'error';
      });
      this.rootStore.notificationStore.addNotification(
        'Failed to get user profile',
        null,
        'error'
      );
      return undefined;
    }
  };

  @action
  auth0ToBHiveToken = async (
    accessToken: string
  ): Promise<string | undefined> => {
    if (isNullOrUndefined(accessToken)) {
      throw new Error('Access token should not be null or undefined!');
    }
    try {
      const { data } = await PURE_API.post(
        '/v3/authentication/auth0_to_bhive',
        { accessToken },
        {
          baseURL: PORTAL_URL,
        }
      );
      return data?.accessToken;
    } catch (error) {
      await this.logout();
    }
  };

  @action
  login = (email: string, password: string) => {
    this.setIsLoggingIn(true);
    this.setFirstLogin(true);
    this.loginStatus = fromPromise(
      API.post(API_ENDPOINTS.AuthLogin, {
        Email: email,
        Password: password,
        Service: 'communicator',
      })
    );
    // Removed toast notification, use inline Message if login failed
    return this.loginStatus.then((resp) => {
      if (IS_ELECTRON && has(window, 'ipcRenderer')) {
        sendIpcSaveCredentials(email, password);
      }
      return this.loginSuccess(resp);
    });
  };

  /**
   * This method performs all necessary operations for logging out a user.
   * It resets the app badge count, shuts down Intercom, updates the user's presence to 'OffLine',
   * pushes 'logout' event to GTM data layer, sets 'isLoggingOut' to true, clears all data from the root store,
   * and removes the SIGN_IN_RESPONSE item from localforage.
   *
   * @throws {Error} If any of the operations fail, an error is thrown.
   *
   * @returns {Promise<void>} A promise that resolves when all operations have completed.
   */
  @action
  processLogoutOperations = async () => {
    // Reset the app badge count. This does not actually affect the unread counts in the core app
    try {
      sendIpcUnreadCounts({
        conversationUnreadCounts: { unreadMentions: 0, unreadMessages: 0 },
        totalUnreadCounts: { unreadMentions: 0, unreadMessages: 0 },
      });
      intercom.shutdown();
      await this.rootStore.uiStore.updatePresence('OffLine', false, '');
      pushToGTMDataLayer('logout', {
        logoutType: 'Explicit',
      });
      this.setIsLoggingOut(true);
      await this.rootStore.clearAllData();
      await localforage.removeItem(SIGN_IN_RESPONSE);
    } catch (error) {
      bugsnagClient.notify('Error during logout:', (event) => {
        event.severity = 'error';
        event.context = 'processLogoutOperations';
        event.addMetadata('custom', { function: 'processLogoutOperations' });
      });
    }
  };

  @action
  logout = async () => {
    try {
      await this.processLogoutOperations();
      this.auth0.logout({
        returnTo: window.location.origin,
      });
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  @action
  resetContactList = () => (this.allContacts = observable.array());

  @action
  loadTopBarContactsOnSuccess = async (
    resp: AxiosResponseT<{
      limit: string;
      page: string;
      items: IContact[];
      total: number;
    }>,
    appendResult = false
  ) => {
    let data = resp.data.items;
    if (appendResult) {
      data = [...this.allContactsTopBar, ...data];
    }
    const newContactListResp = data.map(async (contact) => {
      if (contact.hasProfilePicture && !contact.pictureUrl) {
        return this.loadContactProfilePic(contact);
      } else {
        return Contact.FromResponseDto(contact);
      }
    });
    const newContactList = await Promise.all(newContactListResp);
    runInAction(() => (this.allContactsTopBar = newContactList as any));
    return Promise.resolve(newContactList);
  };

  @action
  getSearchContactsTopBar = async (
    limit = 20,
    page: number = this.contactPageNumber,
    source = '',
    query = '',
    appendResult?: boolean
  ) => {
    try {
      const resp = await API.get(
        ContactsSearchApi(limit, page, '', query, null)
      );
      if (!appendResult && resp.data?.items.length === 0) {
        runInAction(() => (this.allContactsTopBar = observable.array()));
      }
      return resp.data.items?.length > 0
        ? this.loadTopBarContactsOnSuccess(resp, appendResult)
        : [];
    } catch (e) {
      this.rootStore.notificationStore.addNotification(
        'Search had failure',
        null,
        'error'
      );
      return [];
    }
  };

  @action
  getSearchListContacts = async (
    limit = 20,
    page: number = this.contactPageNumber,
    source = '',
    query = '',
    appendResult?: boolean
  ) => {
    const accountId = source && !isNaN(Number(source));
    const sourceType = accountId ? 'EXTERNAL' : source;
    if (!this.loadingContacts) {
      // prevent loader from showing when scrolling on infinite scroll component
      !appendResult
        ? this.setLoadingContact(true)
        : this.setLoaderInfinite(true);
      try {
        const resp = await API.get(
          ContactsSearchApi(
            limit,
            page,
            sourceType,
            query,
            accountId ? Number(source) : null
          )
        );
        if (resp.data?.items.length === 0) {
          this.setLoadingContact(false);
          this.setLoaderInfinite(false);
          //reset in case source is picked, query is not null & page is 1
          page === 1 && (source || query) && this.resetContactList();
        }
        return resp.data.items?.length > 0
          ? this.loadContactsOnSuccess(resp, appendResult)
          : [];
      } catch (e) {
        this.rootStore.notificationStore.addNotification(
          'Search had failure',
          null,
          'error'
        );
        this.setLoadingContact(false);
        this.setLoaderInfinite(false);
        return [];
      }
    }
  };

  changeProviderName = (source) => {
    const lowerCaseSource = source.toLowerCase();
    if (lowerCaseSource.includes('bhive')) {
      return 'B-Hive';
    } else if (lowerCaseSource.includes('icloud')) {
      return 'iCloud';
    } else if (
      lowerCaseSource.includes('outlook') ||
      lowerCaseSource.includes('office365') ||
      lowerCaseSource.includes('eas')
    ) {
      return 'Outlook';
    } else if (['gmail', 'google'].includes(lowerCaseSource)) {
      return 'Gmail';
    }
  };

  checkExternalSources = (participants: IEventParticipants[]) => {
    return participants?.find((participant) => {
      return this.allSources?.find(
        (source) =>
          source.email === participant.email &&
          source.scopes.includes('calendar')
      );
    });
  };

  getSourceByEmailAndProvider = (email: string, provider: string) =>
    this.allSources.find(
      (source) => source.email === email && provider === provider
    );

  createScopeForPost = (source: ISourceAccount, scope: Scope) =>
    uniq([...source.scopes, scope]);

  @action
  getAllContacts = async () => {
    this.setLoadingContact(true);
    try {
      const resp = await API.get(`${BASE_CONTACT_API}contacts`);
      this.loadTopBarContactsOnSuccess(resp, false);
      return this.loadContactsOnSuccess(resp, false);
    } catch (e) {
      this.setLoadingContact(false);
    }
  };

  @action
  loadContactsOnSuccess = async (
    resp: AxiosResponseT<{
      limit: string;
      page: string;
      items: IContact[];
      total: number;
    }>,
    appendResult = false
  ) => {
    let data = resp.data.items;
    this.setHasContact(true);
    this.setTotalContacts(resp.data.total);
    if (appendResult) {
      data = [...this.allContacts, ...data];
    }
    const newContactListResp = data.map(async (contact) => {
      if (contact.hasProfilePicture && !contact.pictureUrl) {
        return this.loadContactProfilePic(contact);
      } else {
        return Contact.FromResponseDto(contact);
      }
    });
    const newContactList = await Promise.all(newContactListResp);
    runInAction(() => (this.allContacts = newContactList as any));
    this.setLoadingContact(false);
    this.setLoaderInfinite(false);
    return Promise.resolve(newContactList);
  };

  loadContactProfilePic = async (contact: IContact) => {
    const newContact = Contact.FromResponseDto(contact);
    try {
      const image = await this.loadContactsPicture(contact.id);
      newContact.pictureUrl = image;
      return newContact;
    } catch (e) {
      this.rootStore.notificationStore.addNotification(
        'Gettings contact picture failed',
        null,
        'error'
      );
      return newContact;
    }
  };

  @action
  loadContactsPicture = async (contactId: number) => {
    try {
      const response = await API.get(
        `${BASE_CONTACT_API}contacts/${contactId}/profile-picture`,
        { responseType: 'arraybuffer' }
      );
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      return 'data:;base64,' + base64;
    } catch (err) {
      bugsnagClient.notify(
        'Failed to load contact profile picture',
        (event) => {
          event.severity = 'error';
        }
      );
    }
  };

  createBody = async (
    code: string,
    provider: string,
    scopesArr: Scope[],
    email?: string,
    password?: string
  ) => {
    const person = await this.personsById.get(this.loggedInPersonId.toString());
    const sameSource = this.getSourceByEmailAndProvider(email, provider);
    const scopes = sameSource
      ? this.createScopeOptions(scopesArr, sameSource)
      : scopesArr;

    const microsoftOauthProviders = ['microsoft', 'office365'];

    const redirectUrl = microsoftOauthProviders.includes(provider.toLowerCase())
      ? `${REDIRECT_URL}/addressBook/sources`
      : REDIRECT_URL;

    return {
      provider: provider.toLowerCase(),
      redirectUri: redirectUrl,
      code,
      email,
      password,
      name: person.data.DisplayName,
      scopes,
    };
  };

  updateSourceListIfNeeded = (provider: string, data: any) => {
    this.setAddedNewSource({ show: true, sourceProvider: provider });
    const newItem = {
      ...data,
      providerImage: this.mapProperLogoProvide(data.provider),
      providerImageSmall: this.mapProperLogoProvide(data.provider, true),
    };
    const isInList = this.allSources.find((item) => item.id === newItem.id);
    const newSourceList = isInList
      ? this.allSources.map((item) => (item.id === newItem.id ? newItem : item))
      : [...this.allSources, newItem];
    runInAction(() => (this.allSources = newSourceList as any));
  };

  @action
  sendCodeOfProvider = async (
    code: string,
    provider: string,
    scopesArr: Scope[],
    email?: string,
    password?: string
  ) => {
    const body = await this.createBody(
      code,
      provider,
      scopesArr,
      email,
      password
    );
    try {
      const resp = await PURE_API.post(`${BASE_CONTACT_API}auth`, body, {
        headers: {
          Authorization: getBearerAuthToken(),
        },
      });
      if (resp.data) this.updateSourceListIfNeeded(provider, resp.data);
      return resp?.data;
    } catch (e) {
      if (e?.response.status === 401) {
        this.rootStore.notificationStore.addNotification(
          'Credentials provided are not valid, try again!',
          null,
          'error'
        );
      } else {
        this.rootStore.notificationStore.addNotification(
          e?.response?.data?.message,
          null,
          'error'
        );
      }
      return false;
    }
  };

  createScopeOptions = (scopesArr: Scope[], sameSource: ISourceAccount) => {
    return uniq(
      scopesArr
        .map((scope) => this.createScopeForPost(sameSource, scope))
        .reduce((arr1, arr2) => arr1.concat(arr2), [])
    );
  };

  @action
  removeSource = async (source: ISourceAccount) => {
    try {
      await API.delete(`${BASE_CONTACT_API}accounts/${source.id}`);
      const filteredSources: any = this.allSources.filter(
        (item) => item.id !== source.id
      );
      const filteredList: any = this.allContacts.filter(
        (item) =>
          item.accountId !== source.id && item.source !== source.provider
      );
      runInAction(() => {
        this.allSources = filteredSources;
        this.allContacts = filteredList;
      });
      return filteredSources;
    } catch (e) {
      this.rootStore.notificationStore.addNotification(
        'Could not perform delete operation',
        null,
        'error'
      );
      return false;
    }
  };

  @action
  resyncSource = async (source: ISourceAccount) => {
    try {
      const res = await API.post(
        `${BASE_CONTACT_API}accounts/${source.id}/sync`
      );
      res.data.providerImage = this.mapProperLogoProvide(res.data.provider);
      res.data.providerImageSmall = this.mapProperLogoProvide(
        res.data.provider,
        true
      );
      runInAction(() => {
        this.allSources = this.allSources.map((source) =>
          source.id === res?.data?.id ? res.data : source
        ) as IObservableArray<ISourceAccount>;
      });
    } catch (e) {
      this.rootStore.notificationStore.addNotification(
        'Too early to sync the account again.',
        null,
        'error'
      );
      return false;
    }
  };

  getSourcesIfMissing = async () => {
    if (this.allSources.length === 0) {
      return this.getSourceAccounts();
    } else {
      return this.allSources;
    }
  };

  mapProperLogoProvide = (provider, small = false) => {
    let providerImage = '';
    switch (provider) {
      case 'google-oauth2':
      case 'gmail':
      case 'google':
        providerImage = small ? googleIconShort : googleIcon;
        break;
      case 'windowslive':
      case 'outlook':
      case 'office365':
      case 'eas':
      case 'ews':
        providerImage = small ? outLookIconShort : outLookIcon;
        break;
      case 'linkedin':
        providerImage = linkedinIcon;
        break;
      case 'microsoft':
        providerImage = small ? microsoftIconShort : microsoftIcon;
        break;
      case 'salesforce':
      case 'salesforce-sandbox':
      case 'salesforce-community':
        providerImage = salesforceIcon;
        break;
      case 'apple':
      case 'icloud':
        providerImage = small ? iCloudIconShort : iCloudIcon;
        break;
      case 'bhive':
        providerImage = small ? bhiveIconShort : bhiveIcon;
    }
    return providerImage;
  };

  //Contacts section
  @action addNewContact = async (contact: Contact) => {
    try {
      const pictureKey =
        this.contactProfilePictureLocally &&
        (await this.uploadContactProfileToS3(contact));
      contact.pictureKey = pictureKey || '';
      this.setAddingNewContact(true);
      const resp = await API.post(BASE_CONTACT_API + 'contacts', contact);
      const newContact = await this.handleAddNewContactSuccess(resp.data);
      this.setAddingNewContact(false);
      return newContact;
    } catch (e) {
      if (e.request.status === 401) {
        this.rootStore.notificationStore.addNotification(
          'Please register your source',
          'Unauthorized',
          'error'
        );
        return;
      }
      if (e.request.response.includes('phoneNumber')) {
        this.setErrorNewContact({
          show: true,
          message: 'Phone number is not in a correct format.',
          field: 'phoneField1',
        });
        return;
      } else if (e.request.response.includes('email')) {
        this.setErrorNewContact({
          show: true,
          message: 'Email is not in a correct format.',
          field: 'emailField1',
        });
        return;
      }
      this.rootStore.notificationStore.addNotification(
        'Adding new contact, issue.',
        null,
        'error'
      );
    }
  };

  getPersonsById = async (
    invitees: number[],
    personCancelToken?: CancelTokenSource
  ) => {
    const allPersonsPbo = invitees.map((id) =>
      this.rootStore.personStore.loadPersonByIdGetIfMissingGet(
        id,
        personCancelToken
      )
    );
    const allPersonsResp = await Promise.all(allPersonsPbo);
    return allPersonsResp.map((personResp) => personResp?.data);
  };

  checkShouldMigrate = (contact: Contact) => {
    const oldContact = this.allContacts.find(
      (currentContact) => currentContact.id === contact.id
    );
    const accountId = oldContact?.accountId || contact.accountId;
    return !!(
      oldContact &&
      ((oldContact.accountId !== contact.accountId && accountId) ||
        oldContact.source !== contact.source)
    );
  };

  deleteContactFromPhonesList = (phoneNum: string, formatedPhone: string) => {
    this.allContactByPhone.delete(phoneNum) ||
      this.allContactByPhone.delete(formatedPhone);
    this.listOfAllContactsWithNumber.delete(phoneNum) ||
      this.listOfAllContactsWithNumber.delete(formatedPhone);
  };

  addRemoveContactInfo = (
    phoneNumber: string,
    formatedPhone: string,
    contact: Contact
  ) => {
    const oldPhoneNum =
      this.editContactDetails &&
      this.editContactDetails.phoneNumbers?.length > 0 &&
      this.editContactDetails.phoneNumbers[0]?.number;
    const formatedOldPhoneNum = formatNumberWithNationalCode(oldPhoneNum);
    const numberChanged =
      phoneNumber !== oldPhoneNum || formatedPhone !== formatedOldPhoneNum;
    if (numberChanged) {
      const [oldContactFound, oldContactLoaded] =
        this.checkIsAlreadyLoadedContact(formatedOldPhoneNum);
      if (oldContactFound && oldContactLoaded) {
        this.deleteContactFromPhonesList(oldPhoneNum, formatedOldPhoneNum);
      }
      this.allContactByPhone.set(formatedPhone, contact);
      return true;
    }
    return false;
  };

  updateInSidebar = (contact: Contact) => {
    const phoneNumber = contact?.phoneNumbers[0]?.number;
    const oldPhoneNum =
      this.editContactDetails?.phoneNumbers?.length > 0 &&
      this.editContactDetails?.phoneNumbers[0]?.number;
    const formatedPhone = formatNumberWithNationalCode(phoneNumber);

    const contactHandled =
      oldPhoneNum &&
      this.addRemoveContactInfo(phoneNumber, formatedPhone, contact);
    if (contactHandled) {
      return;
    }

    const [contactFound, contactAlreadyLoaded] =
      this.checkIsAlreadyLoadedContact(phoneNumber);
    if (contactAlreadyLoaded && contactFound) {
      this.deleteContactFromPhonesList(phoneNumber, formatedPhone);
      this.allContactByPhone.set(formatedPhone, contact);
    }
    // contact is in the list of allContacts and not in the list of phones
    else if (contactAlreadyLoaded && !contactFound) {
      this.listOfAllContactsWithNumber.delete(phoneNumber) ||
        this.listOfAllContactsWithNumber.delete(formatedPhone);
      this.allContactByPhone.set(formatedPhone, contact);
    }
  };

  @action updateContact = async (contact: Contact) => {
    try {
      const pictureKey =
        !contact.accountId &&
        this.contactProfilePictureLocally &&
        (await this.uploadContactProfileToS3(contact));
      contact.pictureKey = contact.accountId
        ? ''
        : pictureKey || contact.pictureKey || '';
      const { ...contactForApi } = contact;

      contactForApi.source = Number.isInteger(contact.selectedSource)
        ? 'EXTERNAL'
        : contact.selectedSource;

      const shouldMigrate = this.checkShouldMigrate(contact);
      const migrateContact =
        shouldMigrate && (await this.updateContactSource(contact));
      const id = migrateContact?.id || contact.id;

      const cleanedFormData = {
        ...omitBy(contactForApi, (value, key) => {
          return key === 'pictureUrl';
        }),
      };

      const resp = await API.put(
        `${BASE_CONTACT_API}contacts/${id}`,
        cleanedFormData
      );
      if (resp) {
        const contactUpdated = new Contact(resp.data as Contact);
        return migrateContact
          ? await this.handleUpdateContactSuccess(
              contactUpdated,
              migrateContact
            )
          : await this.handleUpdateContactSuccess(contactUpdated);
      }
    } catch (e) {
      if (e.request.response.includes('phoneNumber')) {
        this.setErrorNewContact({
          show: true,
          message: 'Phone number is not in a correct format.',
          field: 'phoneField1',
        });
        return;
      } else if (e.request.response.includes('email')) {
        this.setErrorNewContact({
          show: true,
          message: 'Email is not in a correct format.',
          field: 'emailField1',
        });
        return;
      }
      this.rootStore.notificationStore.addNotification(
        'Updating contact, issue.',
        null,
        'error'
      );
    }
  };

  @action updateContactSource = async (contact: Contact) => {
    try {
      const destinationSource =
        typeof contact.selectedSource === 'number'
          ? 'EXTERNAL'
          : contact.selectedSource;
      const destinationAccountId = contact.accountId && contact.accountId;

      const { data: contactResp } = await API.post(
        `${BASE_CONTACT_API}contacts/migrate`,
        {
          id: contact.id,
          destinationSource,
          destinationAccountId,
        }
      );
      if (contactResp) {
        //prevent uploading image in a case of external contact
        if (destinationSource !== 'EXTERNAL') {
          const pictureKey =
            this.contactProfilePictureLocally &&
            (await this.uploadContactProfileToS3(contact));
          contactResp.pictureKey = pictureKey || contactResp.pictureKey || '';
        }
        // this should be true in cases: external => bhive, external <=> external
        // in cases personal <=> shared, id stays the same.
        const shouldBeReplaced = contactResp.id !== contact.id;
        return shouldBeReplaced ? contactResp : null;
      }
    } catch (e) {
      if (e.response.status === 400) {
        this.rootStore.notificationStore.addNotification(
          e.response.data.message,
          null,
          'error'
        );
        return;
      }
      this.rootStore.notificationStore.addNotification(
        'Updating contact, issue.',
        null,
        'error'
      );
    }
  };

  @action
  removeContact = async (contactId: number) => {
    try {
      const resp = await API.delete(`${BASE_CONTACT_API}contacts/${contactId}`);
      if (resp) {
        this.handleRemoveSuccess(contactId);
      }
    } catch (e) {
      this.rootStore.notificationStore.addNotification(
        'Deleting contact, issue.',
        null,
        'error'
      );
    }
  };

  @action
  handleRemoveSuccess = (contactId: number) => {
    const filteredList = this.allContacts.filter(
      (item) => item.id !== contactId
    );
    this.allContacts = filteredList as any;
  };

  @action
  handleUpdateContactSuccess = async (
    contact: Contact,
    replaceContact?: Contact
  ) => {
    const contactIndexToRemove = this.allContacts.findIndex(
      (item) => item.id === contact.id
    );

    let newContact = Contact.FromResponseDto(replaceContact || contact);
    this.updateInSidebar(newContact);

    if (newContact.hasProfilePicture || newContact.pictureKey)
      newContact = await this.loadContactProfilePic(newContact);

    runInAction(() => {
      const updatedContacts = observable.array([...this.allContacts]);
      updatedContacts.splice(contactIndexToRemove, 1, newContact);
      this.allContacts = updatedContacts;
    });
    return newContact;
  };

  @action
  handleAddNewContactSuccess = async (contact: Contact) => {
    let newContact = Contact.FromResponseDto(contact);
    const phoneNum = formatNumberWithNationalCode(
      contact.phoneNumbers[0]?.number
    );
    const newContactList = [...this.allContacts];
    if (contact.hasProfilePicture || contact.pictureKey) {
      newContact = await this.loadContactProfilePic(contact);
    }
    newContactList.unshift(newContact);
    runInAction(() => {
      this.allContacts = newContactList as any;
      phoneNum && this.allContactByPhone.set(phoneNum, newContact);
    });
    return newContact;
  };

  handleUploadContactProfileImg = async (file: File) => {
    try {
      const resp: any = await API.post(
        BASE_CONTACT_API + 'contacts/profile-picture-post',
        { contentType: file.type }
      );
      this.setContactPictureLocally(file);
      return resp?.data?.key;
    } catch (err) {
      bugsnagClient.notify(
        'Failed to save contact profile picture',
        (event) => {
          event.severity = 'error';
        }
      );
      return '';
    }
  };

  @observable
  contactProfilePictureLocally: File = null;

  @action
  setContactPictureLocally = (file: File) =>
    (this.contactProfilePictureLocally = file);

  uploadContactProfileToS3 = async (contact: Contact) => {
    const file = this.contactProfilePictureLocally;
    try {
      const imageData: any = await API.post(
        BASE_CONTACT_API + 'contacts/profile-picture-post',
        { contentType: file.type }
      );
      if (imageData?.data) {
        const formData = this.createFormDataWithFile({ ...imageData.data });
        await fromPromise(
          PURE_API.post(`${imageData.data.url}`, formData, {
            headers: {
              'Content-Type': file.type,
              'Content-Disposition': `attachment; filename=${file.name}`,
            },
          })
        );
        this.contactProfilePictureLocally = null;
        return imageData?.data.key;
      }
      // case if BE doesnt return proper data
      throw null;
    } catch (err) {
      bugsnagClient.notify(
        'Failed to upload contact profile picture to S3',
        (event) => {
          event.severity = 'error';
        }
      );
      return null;
    }
  };

  createFormDataWithFile = ({ fields, key, url }) => {
    const formData = new FormData();
    const file = this.contactProfilePictureLocally;
    Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
    formData.append('file', file);
    return formData;
  };

  @action
  getSourceAccounts = async () => {
    const resp = await API.get(`${BASE_CONTACT_API}accounts`);
    if (resp.data.items) {
      resp.data.items.forEach((item: ISourceAccount, index: number) => {
        item.providerImage = this.mapProperLogoProvide(item.provider);
        item.providerImageSmall = this.mapProperLogoProvide(
          item.provider,
          true
        );
        item.key = index;
        item.value = item.provider;
      });
    }
    runInAction(() => {
      this.allSources = resp.data.items;
    });
    return resp.data.items;
  };

  checkKeyInScopes = (scopes: string[], key: string) =>
    scopes.some((el) => el.includes(key));

  checkAvailabilityPhoneNumber = (inboundNumbers: InboundNumber[]) => {
    /* FIXME:
     * This is a steping stone while we still have to use
     * this MobX store
     * Ideally in the future this will be managed
     * directly through hooks.
     */
    void this.#queryClient.fetchQuery({
      queryKey: [QUERY_KEY_CHECK_AVAILABILITY_PHONENUMBER, ...inboundNumbers],
      queryFn: async () => fetchAvailabilityPhoneNumberQuery(inboundNumbers),
    });
  };

  @action
  private setUserScopes = (
    scopes: string[],
    signedInPerson: IPersonSignInTokenResponse
  ) => {
    const video = { enabled: false, expirationDate: '' };
    if (this.checkKeyInScopes(scopes, 'video')) {
      const timeLeft = this.rootStore.uiStore.getTimeLeft(
        signedInPerson.videoTrialEndsAt
      );
      video.enabled = timeLeft !== 'expired';
      video.expirationDate = signedInPerson.videoTrialEndsAt;
    }
    this.personAvaliableFeatures = {
      video,
      socialAccounts: this.checkKeyInScopes(scopes, 'social_linking'),
    };
  };

  @action
  private loginSuccess = async (
    resp: AxiosResponseT<IPersonSignInTokenResponse>,
    redirectOnLoaded = true,
    forceReloadConversations = false,
    shouldLoadConfig = true
  ) => {
    this.loginStatus =
      fromPromise.resolve<AxiosResponseT<IPersonSignInTokenResponse>>(resp);
    const signedInPerson = resp.data;
    if (window && !isEmpty(resp.data)) {
      window['_currentPerson'] = {
        id: resp.data.id,
        email: resp.data.email,
        account_id: resp.data.account.id,
        location_id: resp.data.location.id,
      };
    }
    this.setLoggedInInfo(signedInPerson);

    const jwt = parseJwt(resp.data.token);

    this.setUserScopes(jwt.scopes, signedInPerson);

    this.rootStore.personStore.setIsAutoLogout(false);
    signedInPerson.role = jwt.acl;
    this.loggedInPersonRole = jwt.acl;
    this.personsById.set(
      signedInPerson.id.toString(),
      fromPromise.resolve({
        ...resp,
        data: PersonModel.FromResponseDto(signedInPerson),
      })
    );

    // this is very important to keep (for now) as it is used for recovering the session after connection loss
    // in the future we can rely on the zustand store with persistence, so we can keep the token across browser sessions
    localforage.setItem(SIGN_IN_RESPONSE, signedInPerson);

    // Since we have this data already fetched, lets save it in the upcoming zustand Person store
    usePersonStore.setState({ ...signedInPerson });
    this.checkAvailabilityPhoneNumber(signedInPerson.inboundNumbers);

    await this.rootStore.contactStore.loadContacts();

    pushToGTMDataLayer('login', {
      accountId: signedInPerson.account.id,
      locationId: signedInPerson.location.id,
      // personId: signedInPerson.id, // Pretty sure we aren't allowed to collect personalized information on this level by the GA EULA (RP 2020-01-30)
      package: signedInPerson.package,
    });

    sendIpcIdentify(
      get(signedInPerson, 'account.id', 0).toString(),
      get(signedInPerson, 'id', 0).toString(),
      `${signedInPerson.firstName} ${signedInPerson.lastName}`,
      signedInPerson.email,
      signedInPerson.package,
      get(signedInPerson, 'locationId', 0).toString()
    );
    await this.loadPeopleGet();
    // Call conditional load by default, otherwise don't bother, since we are going to force reload all conversations
    const loadConversationPromises = !forceReloadConversations
      ? [
          this.rootStore.conversationStore.loadConversationsConditionallyGet(
            false
          ),
          this.rootStore.conversationStore.loadFavoriteConversationsConditionallyGet(
            false
          ),
        ]
      : [];
    if (
      this.rootStore.personStore.allContacts.length === 0 &&
      !this.rootStore.personStore.loadingContacts
    ) {
      this.rootStore.personStore.getAllContacts();
    }
    if (forceReloadConversations) {
      // Fire-and-forget
      this.rootStore.conversationStore.reloadAndBackfillAllConversations();
    }
    const preloadPromises = [
      ...loadConversationPromises,
      this.rootStore.preferenceStore.getExistingPreferenceData(),
      shouldLoadConfig
        ? this.rootStore.configStore.loadConfig()
        : Promise.resolve(),
    ];
    return Promise.all(preloadPromises as any).then(() => {
      this.setIsLoggingIn(false);
      this.rootStore.uiStore.startPresenceReportTick();
      this.rootStore.uiStore.startPresenceSyncTick();
      if (!isNullOrUndefined(this.wakeUpCheckTick)) {
        this.wakeUpCheckTick();
        this.wakeUpCheckTick = null;
      }
      const configData = fromPromise(
        this.rootStore.configStore.signedInPersonConfig
      );
      configData.then((res) => {
        intercom.boot(signedInPerson, jwt);
        return localforage.setItem(CONFIG_RESPONSE, res.data);
      });
      this.startWakeUpCheckTick();
      this.rootStore.uiStore.loadAllPeoplesPresence();
      this.rootStore.uiStore.loadAllMessageStatuses();
      if (redirectOnLoaded) {
        const referrer = get(this.rootStore.routerStore.location, 'state.from');
        if (!isEmpty(referrer)) {
          this.rootStore.routerStore.replace(referrer);
        }
      }
    });
  };

  checkPhoneNumberValidation = (phoneNumber: string) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const countryCode = phoneNumber.startsWith('+') ? '' : 'US';
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(
      phoneNumber,
      countryCode
    );
    return phoneUtil.isValidNumber(parsedPhoneNumber);
  };

  phoneNumInvalid = (phoneNumber: string) => {
    const regex = new RegExp('[a-zA-Z|@*]');
    return regex.test(phoneNumber);
  };

  removeAllSpecialCaracters = (value: string) => {
    return value
      ? value.replace(/[`~!@#$%^&*()_|\-=?;:'",.<>{}\[\]\\\s/]/gi, '')
      : value;
  };

  @action
  updateProfile = (personId: number, person: any) => {
    try {
      const loadPersonPbo = fromPromise<AxiosResponseT<PersonModel>>(
        API.put(API_ENDPOINTS.Profile, person)
      );

      return loadPersonPbo.then(
        (resp) => {
          this.loadPersonByIdGetSuccess(resp);
          return this.personsById.get(personId.toString());
        },
        (reason) => {
          this.loadPersonByIdGetFailure(reason, personId);

          return reason;
        }
      );
    } catch (err) {
      console.warn(
        'Failed to load Person ' +
          personId.toString() +
          ' this may be caused by a removed Person, which is not an error.',
        err
      );
    }
  };

  @action
  uploadProfileImgFileToS3 = async (value: { filename: string }) => {
    const s3RespFile = fromPromise(
      API.post(MSG_API_BASE_URI + 'profile/picture', value)
    );
    s3RespFile.then(
      (resp) => {
        if (!this.fileUploadedS3.has(value.filename)) {
          this.fileUploadedS3.set(value.filename, { ...resp.data });
        }
      },
      (reason) =>
        this.rootStore.notificationStore.addAxiosErrorNotification(
          reason,
          'Error creating file on s3'
        )
    );
    return s3RespFile;
  };

  uploadImportedProfImageFile = async (file: File) => {
    const fileUrl = this.fileUploadedS3.get(file.name);
    if (!isNullOrUndefined(fileUrl)) {
      //overriding interceptor for axios
      const respFile = fromPromise(
        PURE_API.put(fileUrl.uploadProfilePictureUrl, file, {
          headers: {
            'Content-Type': file.type,
            'Content-Disposition': `attachment; filename=${file.name}`,
          },
        })
      );
      respFile.then(
        () => {},
        (reason) =>
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error creating file on s3'
          )
      );
      return respFile;
    }
  };

  updateProfilePicture = async (file: File) => {
    const fileUrl = this.fileUploadedS3.get(file.name);
    if (!isNullOrUndefined(fileUrl)) {
      return this.updateProfile(this.loggedInPersonId, {
        profilePictureUrl: fileUrl.profilePictureUrl,
      });
    }
  };

  handleUploadToAWS = async (person: PersonStore, files: File[]) => {
    const allS3UploadedFiles = files.map((file) => {
      const fileNameArray = file.name.split('.');
      const extension = [...fileNameArray].pop();
      if (extension === 'jpg') {
        fileNameArray.splice(-1, 1, 'jpeg');
        const name = fileNameArray.join('.');
        return person.uploadProfileImgFileToS3({ filename: name });
      } else {
        const name = fileNameArray.join('.');
        return person.uploadProfileImgFileToS3({ filename: name });
      }
    });

    await Promise.all(allS3UploadedFiles);
    const updateAWSLinks = files.map(async (file) => {
      const extension = file.type.split('/')[1];
      const resizedImage = await resizeImage(file, extension);
      await person.uploadImportedProfImageFile(resizedImage);
      return person.updateProfilePicture(resizedImage);
    });
    return Promise.all(updateAWSLinks);
  };
}

export default PersonStore;

// try to login via jwt if its available
export const retrieveAndClearHashToken = () => {
  const tokenIsInHash = loginTokenIsInHash();
  if (tokenIsInHash) {
    try {
      const token = queryString.parse(window.location.hash);

      // https://stackoverflow.com/questions/4631928/convert-utc-epoch-to-local-date
      token.expiresAt = moment.utc(token.auth.exp * 1000).toDate();

      // remove #
      window.location.hash = '';

      return token;
    } catch (err) {
      // remove #
      window.location.hash = '';

      console.error(err);
      return null;
    }
  }
};
