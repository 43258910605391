import { ButtonManageSocialAccount } from 'Components/shared/ButtonManageSocialAccount';
import { ISourceAccount } from 'Interfaces/SourceAccount';
import { LoginResyncButton } from 'Components/LoginResyncButton';
import { MobXProviderContext } from 'mobx-react';
import { NYLAS_V3 } from 'Constants/featureFlags';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import type { SourcesAddressBookItemProps } from './types';
import { Styled } from './index.styles';
import moment from 'moment';
import useFeatureFlags from 'Hooks/featureFlags/useFeatureFlags';

const checkActionTimeout = (source: ISourceAccount) => {
  const dateNow = moment.utc();

  const dateSynced = moment(source.lastSyncedAt || new Date());
  const dateCrated = moment(source.createdAt);
  return {
    createdBefore5min: dateNow.diff(dateCrated, 'minutes') < 5,
    syncBefore5min: dateNow.diff(dateSynced, 'minutes') < 5,
  };
};

export const SourcesAddressBookItem = ({
  source,
  titleFor,
  onShowClick,
  onRemoveClick,
  testId,
}: SourcesAddressBookItemProps) => {
  const { person } = React.useContext<RootStoreProps>(MobXProviderContext);

  const { [NYLAS_V3]: isNylasV3FlagEnabled } = useFeatureFlags([NYLAS_V3]);

  const { createdBefore5min, syncBefore5min } = checkActionTimeout(source);

  const accountUnsynced = [
    'invalid-credentials',
    'invalid',
    'exception',
    'sync-error',
    'stopped',
  ].includes(source.syncState);

  const isProviderNylasV3 = ['google', 'microsoft'].includes(
    source.provider.toLowerCase()
  );

  const shouldMigrate =
    isNylasV3FlagEnabled &&
    !isProviderNylasV3 &&
    source.provider.toLowerCase() !== 'bhive';

  const isGenericUnsync = accountUnsynced;

  const warningMessage = React.useMemo(() => {
    if (source.provider === 'icloud') {
      return 'iCloud isn’t supported anymore. Your contacts won’t be synced.';
    } else if (shouldMigrate) {
      return 'we couldn’t synchronize with this account, please remove and add it again.';
    } else if (isGenericUnsync) {
      return 'this account isn’t synced anymore, please login again.';
    }
    return '';
  }, [source.provider, shouldMigrate, isGenericUnsync]);


  return (
    <Styled.SourceItem>
      <Styled.Contact>
        <Styled.ProviderLogo src={source.providerImageSmall} />
        <span>{source.name}</span>
        {titleFor === 'contacts' && (
          <Styled.Show
            onClick={onShowClick}
            data-testid={`${testId}-buttonShow`}
          >
            SHOW
          </Styled.Show>
        )}
      </Styled.Contact>
      <Styled.Content>{source.text || source.email}</Styled.Content>
      {!source.provider.includes('Bhive') && (
        <Styled.SourceItemActions>
          {source.provider !== 'icloud' && accountUnsynced && !shouldMigrate && (
            <LoginResyncButton
              for={titleFor}
              person={person}
              provider={source.provider}
              disabled={syncBefore5min}
            />
          )}

          <ButtonManageSocialAccount
            action="REMOVE"
            disabled={createdBefore5min}
            icons={{
              enabled: 'source-black-link',
              disabled: 'source-gray-link',
            }}
            onClick={!createdBefore5min ? onRemoveClick : null}
            testid={`${testId}-buttonRemove`}
          />
        </Styled.SourceItemActions>
      )}
      {(accountUnsynced || source.provider === 'icloud' || shouldMigrate) && (
        <Styled.WarningContainer>
          <Styled.WarningIcon icon="warning-filled" />
          <Styled.Warning>
            <Styled.WarningTitle>Warning: </Styled.WarningTitle>
            <Styled.WarningText>
              {warningMessage}
            </Styled.WarningText>
          </Styled.Warning>
        </Styled.WarningContainer>
      )}
    </Styled.SourceItem>
  );
};
